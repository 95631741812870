<template>
  <el-dialog
    id="richTextDialog"
    title="富文本编辑器"
    :visible.sync="show"
    :close-on-press-escape="false"
    destroy-on-close
    @open="open"
    @closed="initial = ''"
  >
    <richText ref="richText" :initial="initial" v-loading="richText_loading" />
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="show = false">取 消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="saveBtn"
        :loading="loading"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    fileName: {
      type: String,
      default: "",
    },
  },
  components: {
    richText: () => import("@/components/richText.vue"),
  },
  data() {
    return {
      show: false,
      loading: false,
      initial: "",
      richText_loading: false,
    };
  },
  methods: {
    async open() {
      if (this.url) {
        this.richText_loading = true;
        try {
          let res = await this.$http.post(
            `/ad/get/html/source?fileUrl=${this.url}`
          );
          if (res.code === 0) {
            this.initial = res.data;
          } else {
            this.$message.error(res.msg);
          }
          this.richText_loading = false;
        } catch (err) {
          this.$message.error(err);
          this.richText_loading = false;
        }
      }
    },
    async saveBtn() {
      try {
        this.loading = true;
        let richText_value = this.$refs.richText.value;
        let res = await this.$http.post("/ad/generate/html", {
          data: `
          <head>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta
              name="viewport"
              content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
            />
            <title>点点行行</title>
          </head>
          <body>${richText_value}</body>
          `,
          fileUrl: this.url ? this.url : null,
        });
        if (res.code === 0) {
          this.$emit("save", res.data);
          this.$message.success("编辑成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (err) {
        this.$message.error(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#richTextDialog {
  & > .el-dialog {
    width: 80% !important;
  }
}
</style>
